import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout/layout"
import {
  MouseParallaxChild,
  MouseParallaxContainer
} from "react-parallax-mouse"
import { Scrollbar } from "react-scrollbars-custom"
import Stars from "../components/stars/stars"
import Settings from "../settings.js"

// import ogImage1 from "../images/persons/og-images/1.jpg"
// import ogImage2 from "../images/persons/og-images/2.jpg"
// import ogImage3 from "../images/persons/og-images/3.jpg"
// import ogImage4 from "../images/persons/og-images/4.jpg"
// import ogImage5 from "../images/persons/og-images/5.jpg"
// import ogImage7 from "../images/persons/og-images/7.jpg"

const MyResultsPage = () => {
  let data = Settings.DavidEinhorn;
  let correctAnswers = 3;
  let averageLeverage = 70;
  let sellOperations = 1;
  let totalBalance = 40000;
  let betterThan = Math.floor(totalBalance * Settings.betterThanFactor);
  let personId = data.hashName;
  let userId;
  let URLdata;
  // let ogImage = '';
  let bitcoinRate;

  if (typeof window !== 'undefined') {
    const id = window.location.search.slice('?data='.length, window.location.search.length);
    URLdata = id.split('-');
    const siteURL = window.location.origin;

    bitcoinRate = (parseFloat(localStorage.getItem('quiz-bitcoin-rate')) > 0) ? parseFloat(localStorage.getItem('quiz-bitcoin-rate')) : 20000;
  
    if (URLdata.length > 1) {
      personId = URLdata[0];
      for (let i in Settings) {
        if (Settings[i].hashName === personId)
          data = Settings[i];
      }
      correctAnswers = URLdata[1];
      averageLeverage = URLdata[2];
      sellOperations = URLdata[3];
      totalBalance = URLdata[4];
      userId = URLdata[5];
      betterThan = Math.floor(totalBalance * Settings.betterThanFactor);
    }
  
    // if (data.index === 1) ogImage = ogImage1;
    // else if (data.index === 2) ogImage = ogImage2;
    // else if (data.index === 3) ogImage = ogImage3;
    // else if (data.index === 4) ogImage = ogImage4;
    // else if (data.index === 5) ogImage = ogImage5;
    // else if (data.index === 7) ogImage = ogImage7;
  
    console.warn(
      'personId: ' + personId + ' | ' +
      'correctAnswers: ' + correctAnswers + ' | ' +
      'averageLeverage: ' + averageLeverage + ' | ' +
      'sellOperations: ' + sellOperations + ' | ' +
      'totalBalance: ' + totalBalance + ' | ' +
      'person index: ' + data.index
    );

   const imageRender = {
     1: '/images/1.jpg',
     2: '/images/2.jpg',
     3: '/images/3.jpg',
     4: '/images/4.jpg',
     5: '/images/5.jpg',
     7: '/images/7.jpg',
   }
  
    return (
      <Layout>
        <Helmet>
          <meta property="og:image" content={siteURL + imageRender[data.index]}/>
          <body className="-blue results" />
        </Helmet>
        <MouseParallaxContainer
          enabled={Settings.parallaxOn}
          className="screen results"
        >
  
          <div className="results__header">
            <p className="results__header__paragraph">
              <span
                data-type="animated-show"
                data-duration="0.5"
                data-delay="0.5"
                data-animation="translateY"
              >User #{userId}. Balance&nbsp;</span>
              <span
                className="results__header__paragraph__accent"
                data-type="animated-show"
                data-duration="0.5"
                data-delay="1"
                data-count-type="count"
                data-count-delay="1.5"
                data-count-finish={totalBalance}
              ><i>$</i><span>{totalBalance.toLocaleString('en')}</span></span>
              <span
                className="results__header__paragraph__accent"
                data-type="animated-show"
                data-duration="0.5"
                data-delay="3"
                data-animation="scaleDown"
              >&nbsp;({((totalBalance / bitcoinRate).toFixed(2)).toLocaleString('en')} BTC)</span>
            </p>
            <p className="results__header__paragraph">
              <span
                data-type="animated-show"
                data-duration="0.5"
                data-delay="4"
                data-animation="scaleDown"
              >User did better than&nbsp;</span>
              <span
                data-type="animated-show"
                data-duration="0.5"
                data-delay="4"
                data-count-type="count"
                data-count-delay="4.5"
                data-count-step="1"
                data-count-finish={betterThan}
              ><span>{betterThan.toLocaleString('en')}</span></span>
              <span
                data-type="animated-show"
                data-duration="0.5"
                data-delay="5.5"
                data-animation="scaleDown"
              >&nbsp;other quiz participants!</span>
            </p>
          </div>
  
          <div className="results__main">
            <div
              className={"results__main__image " + data.imageClass}
              data-type="animated-show"
              data-duration="0.5"
              data-delay="8"
              data-animation="scaleUp"
            ></div>
            <div className="results__main__content">
              <div
                className="results__main__content__text"
                data-type="animated-show"
                data-duration="0.5"
                data-delay="6"
                data-animation="translateY"
              >
                <div className="results__main__content__text__inner">
                  <span
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="6.5"
                    data-animation="translateY"
                  >
                    <Stars />
                  </span>
                  <div
                    className="results__main__content__text__scroll"
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="7"
                    data-animation="translateY"
                  >
                    <Scrollbar>
                      <h3>Your trading style: {data.personName}</h3>
                      <p>{data.text}</p>
                    </Scrollbar>
                  </div>
                </div>
              </div>
              <div
                className="results__main__content__form -button"
                data-type="animated-show"
                data-duration="0.5"
                data-delay="8"
                data-animation="translateY"
              >
                <div
                  data-to="/"
                  className="ui-button-shadow -same-mobile-view -dont-change-width"
                  data-type="animated-show"
                  data-animation="translateY"
                  data-duration="0.5"
                  data-delay="6"
                >
                  <span>Test your skill</span>
                  <i className="ui-button-shadow__icon -lightning"></i>
                </div>
              </div>
            </div>
          </div>
  
          <div
            className="results__description"
            data-type="animated-show"
            data-duration="0.5"
            data-delay="8.5"
            data-animation="translateY"
          >
            <span className="desktop">
              Margex is a reliable and innovative way to trade crypto with up to 100x leverage.<br />
              Margex helps to leverage your trading skills and maximize profits.
            </span>
            <span className="mobile">
              Margex is a reliable and innovative way to trade crypto with up to 100x leverage. Margex helps to leverage your trading skills and maximize profits.
            </span>
          </div>
  
          <div
            className="results__social"
            data-type="animated-show"
            data-duration="0.5"
            data-delay="9"
            data-animation="translateY"
          >
            <a
              href={Settings.twitterURL}
              rel="noopener noreferrer"
              target="_blank"
              className="results__social__item -twitter"
            >Twitter</a>
            <a
              href={Settings.telegramURL}
              rel="noopener noreferrer"
              target="_blank"
              className="results__social__item -telegram"
            >Telegram</a>
            <a
              href={Settings.facebookURL}
              rel="noopener noreferrer"
              target="_blank"
              className="results__social__item -facebook"
            >Facebook</a>
          </div>
  
          <MouseParallaxChild
            className="screen__person"
            factorX={0.04}
            factorY={0.04}
          >
            <div
              className={data.imageClass}
              data-type="animated-show"
              data-duration="0.1"
              data-delay="0.05"
            />
          </MouseParallaxChild>
          <MouseParallaxChild
            className="screen__dots"
            factorX={0.12}
            factorY={0.12}
          >
            <div
              className="-left"
              data-type="animated-show"
              data-duration="0.1"
              data-delay="0.15"
            />
          </MouseParallaxChild>
          <MouseParallaxChild
            className="screen__dots"
            factorX={0.4}
            factorY={0.4}
          >
            <div
              className="-right"
              data-type="animated-show"
              data-duration="0.1"
              data-delay="0.55"
            />
          </MouseParallaxChild>
          <MouseParallaxChild
            className="screen__chart"
            factorX={0.16}
            factorY={0.16}
          >
            <div
              className="-green -down"
              data-type="animated-show"
              data-animation="scaleDown"
              data-duration="0.1"
              data-delay="0.2"
            />
          </MouseParallaxChild>
          <MouseParallaxChild
            className="screen__chart"
            factorX={0.48}
            factorY={0.48}
          >
            <div
              className="-green -up"
              data-type="animated-show"
              data-animation="scaleUp"
              data-duration="0.1"
              data-delay="0.6"
            />
          </MouseParallaxChild>
          <MouseParallaxChild
            className="screen__bitcoin"
            factorX={0.24}
            factorY={0.24}
          >
            <div
              className="-bottom"
              data-type="animated-show"
              data-animation="scaleUp"
              data-duration="0.1"
              data-delay="0.3"
            />
          </MouseParallaxChild>
        </MouseParallaxContainer>
      </Layout>
    )
  }
}

export default MyResultsPage
